<template>
    <div class="container">

        <HeaderComponent></HeaderComponent>

        <BackgroundComponent></BackgroundComponent>
        
        <section>
            <div class="containerSection">
                <div class="ttrSection">
                    <h2> Estimation gratuite </h2>
                </div>
                <div class="prt2" v-if="currentStep === 2">
                    <form action="">
                        <div class="ttr">
                            <h4>Caractéristiques du bien</h4>
                        </div>
                        <div class="case">
                            <label for="bien">Type de bien</label>
                            <select name="bien" id="bien" v-model="FormData.bien">
                                <option value="maison">Maison</option>
                                <option value="appartement">Appartement</option>
                                <option value="chambre de service">chambre de service</option>
                                <option value="terrain">Terrain</option>
                                <option value="local">garage</option>
                            </select>
                        </div>
                        <div class="case">
                            <label for="habitable"> Surface habitable </label>
                            <input type="text" name="habitable" v-model="FormData.habitable">
                        </div>
                        <div class="case">
                            <label for="habitable"> Surface terrain </label>
                            <input type="text" name="terrain" v-model="FormData.terrain">
                        </div>
                        <div class="case">
                            <label for="pieces"> Nombre de pièces</label>
                            <input type="number" name="pieces" v-model="FormData.pieces">
                        </div>
                        <div class="case">
                            <label for="chambres"> Nombre de pièces</label>
                            <input type="number" name="chambres" v-model="FormData.chambres">
                        </div>
                        <div class="case">
                            <label for="etage"> Etage </label>
                            <input type="number" name="etage" v-model="FormData.etage">
                        </div>
                        <div class="case">
                            <label for="niveau"> Niveau </label>
                            <input type="number" name="niveau" v-model="FormData.niveau">
                        </div>
                        <div class="case">
                            <label for="constuction"> Année de construction </label>
                            <input type="number" name="constuction" v-model="FormData.constuction">
                        </div>
                        <div class="case radio">
                            <label for="Arenover">A rénover</label>
                            <div class="choix">
                                <input type="radio" name="Arenover" value="Oui" v-model="FormData.Arenover"> Oui
                                <input type="radio" name="Arenover" value="Non" v-model="FormData.Arenover"> Non
                            </div>
                        </div>
                        <div class="case radio">
                            <label for="Constructible"> Constructible </label>
                            <div class="choix">
                                <input type="radio" name="Constructible" value="Oui" v-model="FormData.Constructible"> Oui
                                <input type="radio" name="Constructible" value="Non" v-model="FormData.Constructible"> Non
                            </div>
                        </div>
                        <div class="case radio">
                            <label for="piscine"> Piscine </label>
                            <div class="choix">
                                <input type="radio" name="piscine" value="Oui" v-model="FormData.piscine"> Oui
                                <input type="radio" name="piscine" value="Non" v-model="FormData.piscine"> Non
                            </div>
                        </div>
                        <div class="case radio">
                            <label for="balcon"> Balcon/terrasse </label>
                            <div class="choix">
                                <input type="radio" name="balcon" value="Oui" v-model="FormData.balcon"> Oui
                                <input type="radio" name="balcon" value="Non" v-model="FormData.balcon"> Non
                            </div>
                        </div>
                        <div class="button">
                            <button @click.prevent="validationForm"> Suivant </button>
                            <div class="error">
                                <span> {{ error }} </span>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="prt2 loc" v-if="currentStep === 3">
                    <form action="">
                        <div class="ttr">
                            <h4>Localisation</h4>
                        </div>
                        <div class="case">
                            <label for="adresse"> Adresse </label>
                            <input type="text" name="adresse" v-model="FormData.adresse">
                        </div>
                        <div class="case">
                            <label for="cdp"> Code Postale </label>
                            <input type="number" name="cdp" v-model="FormData.cdp">
                        </div>
                        <div class="case">
                            <label for="ville"> Ville </label>
                            <input type="texte" name="ville" v-model="FormData.ville">
                        </div>
                        <div class="button nbr3">
                            <button @click.prevent="validationFormprt2"> Suivant </button>
                            <div class="error">
                                <span> {{ error }} </span>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="prt2" v-if="currentStep === 4">
                    <form action="">
                        <div class="ttr">
                            <h4>A propos de vous</h4>
                        </div>
                        <div class="case">
                            <label for="nom"> Nom </label>
                            <input type="text" name="nom" v-model="FormData.nom">
                        </div>
                        <div class="case">
                            <label for="prenom"> Prénom </label>
                            <input type="text" name="prenom" v-model="FormData.prenom">
                        </div>
                        <div class="case">
                            <label for="mail"> E-mail </label>
                            <input type="text" name="mail" v-model="FormData.mail">
                        </div>
                        <div class="case">
                            <label for="num"> Numéro de téléphone </label>
                            <input type="number" name="num" v-model="FormData.num">
                        </div>
                        <textarea name="" id="" cols="30" rows="10" v-model="FormData.message"></textarea>
                        <div class="button nbr4">
                            <button @click.prevent="validationFormprt3"> Valider </button>
                            <div class="error">
                                <span> {{ error }} </span>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="prt2" v-if="currentStep === 5">
                    <div class="confirmation">
                        <div v-if="error"> {{ error }} </div>
                        <div v-else> Bravo ! Vous avez remplie toutes les informations demandé dans le formulaire. Nous vous confirmons l'envois de vos données. Nous reviendrons vers vous dans les jours à venir, merci pour votre confiance, à bientôt</div>
                    </div>
                </div>

            </div>
        </section>

        <footerComponent></footerComponent>

    </div>
</template>

<script>
import HeaderComponent from '../components/HeaderComponent.vue';
import footerComponent from '@/components/footerComponent.vue';
import BackgroundComponent from '@/components/BackgroundComponent.vue';
import axios from 'axios';

export default {
  components:{
    HeaderComponent,
    footerComponent,
    BackgroundComponent,
  },
  data() {
    return {
      currentStep: 2,
      error : "",
      FormData : {
        bien : "",
        habitable : "",
        terrain : "",
        pieces : "",
        chambres : "",
        etage : "",
        niveau : "",
        constuction : "",
        Arenover : "",
        Constructible : "",
        piscine : "",
        balcon: "",
        adresse: "",
        cdp: "",
        ville: "",
        nom: "",
        prenom: "",
        mail : "",
        num : "",
        message: "",
      }
    };
  },
  methods: {
    nextStep() {
        this.currentStep++;
    },
    validationForm() {
        if(this.FormData.bien === ""){
            this.error = "Veuillez remplire tous les champs !";
        }else{
            if(this.FormData.habitable === ""){
                this.error = "Veuillez remplire tous les champs !";
            }
            else{                
                if(this.FormData.terrain === ""){
                this.error = "Veuillez remplire tous les champs !";
                }
                else{
                    if(this.FormData.pieces === ""){
                        this.error = "Veuillez remplire tous les champs !";
                    }
                    else{
                        if(this.FormData.chambres === ""){
                            this.error = "Veuillez remplire tous les champs !";
                        }
                        else{
                            if(this.FormData.etage === ""){
                                this.error = "Veuillez remplire tous les champs !";
                            }
                            else{
                                if(this.FormData.niveau === ""){
                                  this.error = "Veuillez remplire tous les champs !";
                                }
                                else{
                                    if(this.FormData.constuction === ""){
                                        this.error = "Veuillez remplir tous les champs, ou les remplir correctement !";
                                    }
                                    else{
                                        if(this.FormData.Arenover === ""){
                                          this.error = "Veuillez remplire tous les champs !";
                                        }
                                        else{
                                            if(this.FormData.Constructible === ""){
                                                this.error = "Veuillez remplire tous les champs !";
                                            }
                                            else{
                                                if(this.FormData.piscine === ""){
                                                    this.error = "Veuillez remplire tous les champs !";
                                                }
                                                else{
                                                    if(this.FormData.balcon === ""){
                                                        this.error = "Veuillez remplire tous les champs !";
                                                    }
                                                    else{
                                                        this.currentStep++;
                                                        this.error = "";
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    validationFormprt2(){
        if (this.FormData.adresse === "") {
            this.error = "Veuillez remplire tous les champs !"
        }else{
            if (this.FormData.cdp === "") {
                this.error = "Veuillez remplire tous les champs !"
            }else{
                if (this.FormData.ville === "") {
                    this.error = "Veuillez remplire tous les champs !"
                }else{
                    this.currentStep++;
                    this.error = "";
                }
            }
        }
    },
    validationFormprt3() {
        if (this.FormData.nom === "" || this.FormData.prenom === "" || this.FormData.mail === "" || this.FormData.num === "") {
            this.error = "Veuillez remplir tous les champs !";
        } else if (!this.validateEmail(this.FormData.mail)) {
            this.error = "Veuillez fournir une adresse e-mail valide !";
        } else if (!this.validatePhone(this.FormData.num)) {
            this.error = "Veuillez fournir un numéro de téléphone valide (10 chiffres) !";
        } else {
            this.sendData();
        }
    },

    // Validation de l'e-mail
        validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    },

    // Validation du numéro de téléphone
    validatePhone(phone) {
        const phoneRegex = /^\d{9}$/; // Vérifie si le numéro a exactement 10 chiffres
        return phoneRegex.test(phone);
    },

    sendData() {
        const data = {
            bien: this.FormData.bien,
            habitable: this.FormData.habitable,
            terrain: this.FormData.terrain,
            pieces: this.FormData.pieces,
            chambres: this.FormData.chambres,
            etage: this.FormData.etage,
            niveau: this.FormData.niveau,
            construction: this.FormData.construction,
            arenover: this.FormData.arenover,
            constructible: this.FormData.constructible,
            piscine: this.FormData.piscine,
            balcon: this.FormData.balcon,
            adresse: this.FormData.adresse,
            cdp: this.FormData.cdp,
            ville: this.FormData.ville,
            nom: this.FormData.nom,
            prenom: this.FormData.prenom,
            mail: this.FormData.mail,
            num: this.FormData.num,
            message: this.FormData.message
        };

        axios.post('http://localhost:8084/estimationGratuite', data)
        .then((res) => {
            console.log(res.data);
            this.currentStep++;
        })
        .catch((err) => {
            console.log(err);
            this.error = err;
        });
    }
},
}
</script>

<style scoped>

.container {
  position: absolute;
  width: 100%;
  height: fit-content; 
  overflow: hidden;
}

section{
    width: 100%;
    height: fit-content;
    margin-top: 100px;
    padding-bottom: 80px;
}

.containerSection{
    width: 70%;
    height: fit-content;
    margin: auto;
    background-color: #fff;
    padding: 30px 15px 60px 15px;
}

.ttrSection{
    width: calc(95% - 30px);
}

.ttrSection h2{
    font-family: avenir;
    font-size: 38px;
    color: #182D45;
    position: relative;
    left: 30px;
    margin-bottom: 0;
}
.ttrSection p{
    font-family: avenir;
    font-size: 18px;
    color: #182D45;
    position: relative;
    left: 30px;
    margin-top: 20px;
}

.prt1{
    width: calc(100% - 40px);
    position: relative;
    left: 30px;
    margin-top: 80px;
}
.prt1 button{
    width: 140px;
    height: 40px;
    background-color: #d7d7d753;
    cursor: pointer;
    font-family: avenir;
    transition: 450ms ease-in-out;
}
.prt1 button:hover{
    background-color: #fff;
}
.prt2{
    width: calc(100% - 40px);
    position: relative;
    left: 30px;
    margin-top: 20px !important;
}

.loc{
    height: 300px;
}

.prt2 form{
    width: calc(100% - 40px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(7, 73px);
    column-gap: 30px;
}
.ttr{
    grid-column-start: 1;
    grid-column-end: 3;
    font-family: avenir;
    font-size: 18px;
    font-weight: 300;
}

.case{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.case input, select{
    max-width: 80%;
    min-width: 40%;
    height: 25px;
}

.case .choix{
    width: 150px;
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.case .choix input{
    width: 20px;
    height: 20px;
}

.button button{
    width: 140px;
    height: 40px;
    background-color: #d7d7d753;
    cursor: pointer;
    font-family: avenir;
    transition: 450ms ease-in-out;
    margin-top:  30px;
}

.button button:hover{
    background-color: #fff;
}

.nbr3{
    grid-column-start: 1;
    grid-column-end: 3;
}

.nbr4{
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-end: 8;
}

textarea{
    min-width: 75%;
    height: 200px;
    grid-column-start: 1;
    grid-column-end: 3;
    margin-top: 15px;
}

.error{
    margin-top: 6px;
}
.error span{
    font-size: 12px !important;
    color: rgb(175, 35, 35);
    font-family: avenir;
    font-style: italic;
}
.confirmation{
    font-family: avenir;
    font-size: 16px;
}
.confirmation:nth-child(1){
    color: rgb(26, 103, 26);
}
.confirmation:nth-child(2){
    color: rgb(175, 35, 35);
}

@media only screen and (max-width: 920px) {
    section{
        margin-top: 200px !important;
    }
}

@media only screen and (max-width: 830px) {
    .case .choix {
        width: 100px;
        justify-content: left;
    }
}

@media only screen and (max-width: 830px) {
    .containerSection{
        width: calc(100% - 30px);
        height: fit-content;
        margin: auto;
        background-color: #fff;
        padding: 30px 15px 60px 15px;
    }
}

/* téléphone version */

@media only screen and (max-width: 450px) {
    .prt2 {
        left: 0px;
    }
    .radio {
        grid-column-start: 1 !important;
        grid-column-end: 3 !important;
        margin-bottom: 20px;
    }
}


</style>