<template>
  <div class="container">
    <img :src="currentImage" alt="Background Image" class="background-image">
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        '/img/financiel_immo_background1.jpeg',
        '/img/financiel_immo_background2.jpeg',
        '/img/financiel_immo_background3.jpeg',
      ],
      currentIndex: 0,
      currentImage: null,
      intervalId: null,
    };
  },
  mounted() {
    this.startImageRotation();
  },
  methods: {
    startImageRotation() {
      this.rotateImage();
      this.intervalId = setInterval(() => {
        this.rotateImage();
      }, 30000);
    },
    rotateImage() {
      this.currentImage = this.images[this.currentIndex];
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
};
</script>



<style scoped>
.container {
  position: relative;
  width: 100%;
  height: auto !important;
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.container::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #182d4585;
  opacity: 0.60;
  z-index: -1;
}

</style>
