<template>
    <section>
        <div class="containerSlider">
            <h2> Témoignage client </h2>
            <div class="boxReviews">
                <p>
                    Annonce trouvée sur se loger, extrême réactivité pour caler une visite de la part de Karen A., la directrice qui était en charge de ce bien. Dans un marché de la location extrêmement tendu, il est très agréable d’avoir une intermédiaire aussi sympathique, a l’écoute, professionnelle et ultra réactive. Je recommande sans reserve!
                </p>
                <span> Clara da Silva </span> <br>
                <span> ⭐⭐⭐⭐⭐ </span>
                <div class="sources">
                    <span> <img src="https://img.icons8.com/?size=512&id=17949&format=png" alt="source"> </span> 
                </div>
            </div>
            <div class="boxReviews">
                <p>
                    Karen Abisror m'a accompagnée dans une recherche d'appartement. Elle a été toujours disponible pour les visites et m'a donné de précieux conseils pour tenir compte de la réalité du marché et pas seulement de mes attentes. Enfin, elle est très agréable, ce qui facilite les échanges.
                </p>
                <span> Cecile ECALLE </span> <br>
                <span> ⭐⭐⭐⭐⭐ </span>
                <div class="sources">
                    <span> <img src="https://img.icons8.com/?size=512&id=17949&format=png" alt="source"> </span> 
                </div>
            </div>
            <div class="boxReviews">
                <p>
                    Un accompagnement d'une rare qualité dans le domaine de l'immobilier. Sympathique, agréable, à l'écoute. Dans un domaine où tout va à 200 à l'heure, ici on le prend le temps d'être là pour le locataire, habituellement seul dans le stress de la recherche. Je recommande cette agence et leur renouvelle mes remerciements.
                </p>
                <span> Fazia Boudefoua </span> <br>
                <span> ⭐⭐⭐⭐⭐ </span>
                <div class="sources">
                    <span> <img src="https://img.icons8.com/?size=512&id=17949&format=png" alt="source"> </span> 
                </div>
            </div>
            <div class="boxReviews">
                <p>
                    Karen ABISROR est professionnelle, accrocheuse, cordiale, courtoise, joyeuse. Karen prend en compte la demande des deux parties dans une négociation pour que celle-ci se concrétise et aboutisse dans les meilleurs délais. Tout les bons côtés d'une commerciale avec une bonne maitrise de la réglementation, malgré son évolution constante. Reste impliquée jusqu'à la signature et la remise des clefs. Humainement, un esprit ouvert avec de vraies convictions et un caractère généreux !
                </p>
                <span> Olivier de Champroux </span> <br>
                <span> ⭐⭐⭐⭐⭐ </span>
                <div class="sources">
                   <span> <img src="https://img.icons8.com/?size=512&id=17949&format=png" alt="source"> </span> 
                </div>
            </div>
            <div class="boxReviews">
                <p>
                    Agence très accueillante, personnel très compétent et réactif. La direction est à l’écoute des besoins mais surtout TRÈS PROFESSIONNELLE. Je leur confie tous mes biens tant à la vente et location depuis des années à chaque fois tout est parfait vraiment je vous conseille cette agence hautement qualifiée et réputée pour son savoir faire                </p>
                <span> VALERIE ATLAN </span> <br>
                <span> ⭐⭐⭐⭐⭐ </span>
                <div class="sources">
                    <span> <img src="https://img.icons8.com/?size=512&id=17949&format=png" alt="source"> </span> 
                </div>
            </div>
        </div>
    </section>
</template>


<style scoped>

section{
    width: 100%;
    background-color: #ffffffc2;
}
.containerSlider{
    position : relative;
    width: 95%;
    max-width: 1500px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 80px;
    padding: 140px 0;
}

.containerSlider h2{
    display: block;
    width: 100%;
    text-align: center;
    color: #182d45;
    font-family: avenir;
    font-size: 45px;
}
.boxReviews{
    position: relative;
    width: 18%;
    min-width: 300px;
    border-radius: 7px;
    margin: auto;
    height: fit-content;
    padding: 55px 55px 30px 55px;
    background-color: #fff;
    text-align: center;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 3px 3px 7px 2px #a1a1a1;
}

.boxReviews p{
    font-size: 16px;
    font-weight: 500;
    font-family: Avenir;
    color: #182d45;
}

.LeftArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.RightArrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    right: 0;
    cursor: pointer;
}

.boxReviews span:nth-child(2){
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    font-family: Avenir;
    padding-top: 100px !important;
    color: #333;
    text-decoration: underline;
}

.boxReviews .sources {
    position: absolute;
    right: 20px;
    bottom: 10px;
}

.boxReviews .sources img {
    width: 25px;
    height: 25px;
}
@media only screen and (max-width: 1050px) {

    .containerSlider {
        width: 810px;
        display: flex;
        flex-direction: column;
    }

    .LeftArrow {
        position: relative;
        transform: translateY(-0%);
        cursor: pointer;
    }

    .LeftArrow img, .RightArrow img{
        width: 40px;
    }

    .RightArrow{
        position: relative;
        transform: rotate(180deg);
    }  
}

@media only screen and (max-width: 827px) {
    .containerSlider{
        width: 90%;
    }
    .boxReviews{
        width: 80%;
    }

}

@media only screen and (max-width : 450px) {
    .boxReviews{
        width: calc(100% - 60px);
        padding: 25px 30px 10px 30px;
    }

    .containerSlider {
        position: relative;
        width: 95%;
        margin: auto;
        display: flex;
        padding: 80px 0;
    }

}

</style>
