<template>
  <div class="containers">
    
    <HeaderComponent></HeaderComponent>

    <section class="bannerHeader">
      <div class="leftTextHeaderbanner">
        <div class="titreAgence">
          <h1>
            <span>FINANCIEL</span>
            <span>Immobilier</span>
          </h1>
        </div>
      </div>
      <div class="rightFilterHeaderbanner">
        <div class="divcontainer">
          <div class="cadreFilter">
            <div class="ContaintFilterdiv">
              <div class="ttrFiltre">
                <span>Recherche rapide</span>
              </div>
              <div class="error">
                <span class="error" v-if="formData.error">{{ formData.error }}</span>
              </div>
              <form action="" class="formFilter">
                <div class="caseFilter">
                  <span> Achat / Location </span>
                  <select name="achatLoc" id="choix1" v-model="formData.typeTransacte">
                    <option value="vente"> Vente </option>
                    <option value="location"> Location </option>
                  </select>
                </div>
                <div class="caseFilter">
                  <span> Tous les types </span>
                  <select name="types" id="choix2" v-model="formData.toutType">
                    <option value="Appartement"> Appartement </option>
                    <option value="Studio"> Studio </option>
                    <option value="Chambre de service"> Chambre de service </option>
                    <option value="Bureaux"> Bureaux </option>
                    <option value="Parking"> Parking </option>
                  </select>
                </div>
                <div class="caseFilter">
                  <span> Surface minimum (m2) </span>
                  <input type="text" v-model="formData.surfaceMin">
                </div>
                <div class="caseFilter">
                  <span> Surface maximum (m2) </span>
                  <input type="text"  v-model="formData.surfaceMax">
                </div>
                <div class="caseFilter">
                  <span> Prix minimum (€) </span>
                  <input type="number" v-model="formData.prixMin">
                </div>
                <div class="caseFilter">
                  <span> Prix maximum (€) </span>
                  <input type="number"  v-model="formData.prixMax">
                </div>
                <div class="caseFilter">
                  <span> Villes </span>
                  <select name="types" id="choix3" v-model="formData.villes">
                    <option value="">-- Select a city --</option>
                    <option v-for="filter in filteredForms" :value="filter" :key="filter">{{ filter }}</option>
                  </select>
                </div>
                <div class="caseFilter">
                  <span> Réference </span>
                  <input type="text"  v-model="formData.ref">
                </div>
                <div class="btnFilter">
                  <button @click.prevent="submitForm"> Rechercher </button>
                </div>
              </form>
              <div class="logo">
                <img src="../assets/instagram.png" alt="lien_instagram">
                <img src="../assets/facebook.png" alt="lien_facebook">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="results" v-if="showResults">
      <h2>Résultat de la recherche</h2>
      <ul>
        <div class="annonce left" v-for="bien in filteredBiens" :key="bien.idbien">
          <div class="imageAnnonce">
            <img :src="getImageUrl(bien.images[0])" alt="images annonce">
          </div>
          <div class="contentAnnonce">
            <div class="prix">
              <span>{{ formatPrice(bien.prix) }}</span>
            </div>
            <div class="ttrAnnonce">
              <span> {{ bien.type_bien[0] }} - {{ bien.ville[0] }} </span>
            </div>
            <div class="buttonAnnonce">
              <button> 
                <router-link :to="`/annoncesD/${bien.$.id}`"> 
                  Voir plus 
                </router-link> 
              </button>
            </div>
          </div>
        </div>
      </ul>
    </div>


    <AnnonceComponent></AnnonceComponent>

    <ReviewsComponent></ReviewsComponent>

    <footerComponent></footerComponent>

    <BackgroundComponent></BackgroundComponent>

  </div>

</template>

<script>
import HeaderComponent from './HeaderComponent.vue';
import AnnonceComponent from './AnnonceComponent.vue';
import ReviewsComponent from './ReviewsComponent.vue';
import FooterComponent from './footerComponent.vue';
import BackgroundComponent from './BackgroundComponent.vue';
import axios from 'axios';
const parser = require('xml2js').parseStringPromise;

export default {
  components: {
    HeaderComponent,
    AnnonceComponent,
    ReviewsComponent,
    FooterComponent,
    BackgroundComponent,
  },
  data(){
    return{
      showResults: false,
      biens: [],
      filteredBiens: [],
      filteredForms: [],
      formData : {
        typeTransacte : '',
        toutType : '',
        surfaceMin : '',
        surfaceMax : '',
        prixMin : '',
        prixMax : '',
        villes : '',
        ref : '',
        error : '',
      }
    }
  },
  mounted() {
    axios
    .get(`./img/base.xml`)
    .then((response) => {
      const xmlData = response.data;
      return parser(xmlData);
    })
    .then((jsonData) => {
      this.biens = jsonData.biens.bien;

      const uniqueCities = [...new Set(this.biens.map((bien) => bien.ville[0]))];

      this.filteredForms = uniqueCities;
    })
    .catch((error) => {
      console.log(error);
    });
  },

  methods: {
    submitForm() {
    if (this.formData.typeTransacte === "" && this.formData.toutType === "") {
      this.formData.error = "Remplir les champs suivant pour continuer : 'Achat / Location' et 'Tous les types' ";
    } else {
      this.formData.error = "";
      this.showResults = true;
      this.filterForms();
    }
  },
    filterForms() {
      const filteredBiens = this.biens.filter((bien) => {
      const isTypeMatch = bien.type_bien[0] === this.formData.toutType;
      const isTypeTransaction = bien.type_transaction[0] === this.formData.typeTransacte;
      const surface = parseInt(bien.surface_habitable[0]);
      const price = parseInt(bien.prix[0]);
      const city = bien.ville[0];

      
      const isSurfaceAboveMin = (!this.formData.surfaceMin || surface >= parseInt(this.formData.surfaceMin));
      const isSurfaceBelowMax = (!this.formData.surfaceMax || surface <= parseInt(this.formData.surfaceMax));
      const isPriceAboveMin = (!this.formData.prixMin || price >= parseInt(this.formData.prixMin));
      const isPriceBelowMax = (!this.formData.prixMax || price <= parseInt(this.formData.prixMax));
      const isCityMatch = (!this.formData.villes || this.formData.villes === city);

      return isTypeMatch && isTypeTransaction && isSurfaceAboveMin && isSurfaceBelowMax && isPriceAboveMin && isPriceBelowMax && isCityMatch;
    });

    this.filteredBiens = filteredBiens;
    },
    formatPrice(price) {
      const formattedPrice = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      }).format(price);
      return formattedPrice;
    },
    getImageUrl(imageName) {
      const imageKeys = Object.keys(imageName);
      const imageArray = [];
  
      for (const key of imageKeys) {
        if (key !== 'image_princ_min') {
          imageArray.push(imageName[key]);
        }
      }
  
      const concatenatedArray = [].concat(...imageArray);
  
      if (concatenatedArray.length > 0) {
        const imageUrl = concatenatedArray[0];
        return `./img/${imageUrl}`;
      }
  
      return '';
    },
  },

};
</script>



<style scoped>

.containers{
  width: 100%;
  overflow-x: hidden !important;
}

.bannerHeader{
  width: 100%;
  display: flex;
  padding-top: 20px;
}

header{
  width: 100%;
  overflow: hidden;
  height: fit-content;
}

.leftTextHeaderbanner, .rightFilterHeaderbanner{
  width: 50%;
  display: flex;
  flex-direction: column;
}

.leftTextHeaderbanner .AgenceImmo{
  font-family: Avenir;
  font-size: 16px;
  letter-spacing: 0.9px;
  color: #A5CDE6;
}

.titreAgence{
  margin: 163px 0px 3px 18%;
}

.titreAgence h1{
  font-size: 80px;
  display: flex;
  flex-direction: column;
  column-gap: -20px;
  margin: 0;
  font-family: Avenir;
  font-weight: 300;
  color: #fff;
}
.titreAgence h1 span:nth-child(2){
  font-style: italic;
  margin-top: -5px;
}

.titreAgence p{
  color: #fff;
  font-family: avenir;
  font-weight: 300;
  font-size: 14px;
}

.divcontainer{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cadreFilter{
  position: relative;
  margin: 70px 0 0 28% !important;
  width: 442px;
  min-height: 629px;
  border: 10px solid #fff;
  background-color: #ffffff15;
}

.ContaintFilterdiv{
  position: absolute;
  transform: translate( -35px, 22px);
  width: 455px;
  min-height: 669px;
  background-color: rgba(13,25,38);
}

.ttrFiltre{
  display: flex;
  flex-direction: column;
}

.ttrFiltre{
  position: relative;
  left: 40px;
  margin: 59px 0px 11px 0;
}

.ttrFiltre span:nth-child(1){
  color: rgba(165,205,230);
  letter-spacing: 0.05;
  line-height: 1em;
  font-family: Avenir;
  font-weight: 500;
  font-size: 38px;
  margin-bottom: 11px;
}

.ttrFiltre span:nth-child(2){
  color: #fff;
  font-size: 13px;
  font-family: Avenir;
  font-weight: 300;
  line-height: 1em;
}

.formFilter{
  position: relative;
  left: 40px;
  max-width: 392px;
  height: 484px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 67px);
  row-gap: 20px;
  margin-top: 30px;
  font-family: Avenir;
  font-size: 14px;
}
.caseFilter{
  display: flex;
  flex-direction: column;
  color: rgba(165,205,230);
  width: 177px;
}

.caseFilter span{
  margin-bottom: 14px;
}

.cadreFilter select{
  width: calc(100%);
  padding-left: 8px !important;
  height: 37px !important;
  border: 0;
  color: #182D45;
  font-family: Avenir;
  font-size: 13px;
}

.cadreFilter input{
  width: calc(100% - 10px);
  padding-left: 8px !important;
  height: 37px !important;
  border: 0;
}

.cadreFilter input::placeholder{
  color: #182D45;
  font-family: Avenir;
  font-size: 13px;
}

.btnFilter{
  width: 367px;
  grid-column-start: 1;
  grid-column-end: 3;
  margin: 30px 0 7px 0 !important;
}

.btnFilter button{
  width: 100%;
  height: 47px;
  background-color: #A5CDE6;
  font-size: 17px;
  font-family: Avenir;
  font-weight: 500;
  letter-spacing: 0.5px;
  cursor: pointer;
  border: none;
}

.error{
  max-width: 400px !important;
  text-align: left;
  color: red;
  font-style: italic;
  position: relative;
  left : 25px;
  top: -5px;
}

.logo{
  width: fit-content;
  position: relative;
  left: 40px;
  bottom: 15px;
}
.logo img{
  width: 22px;
  height: 22px;
  object-fit: cover;
  margin-right: 20px;
  cursor: pointer;
}

/* Filter */

.results{
  position: relative;
  left: 40px;
  margin-top: 150px;
}

.results h2{
  position: relative;
  left: 40px;
  color: #fff;
  font-family: avenir;
  font-size: 28px;
  font-weight: 400;
}
.results ul{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  padding-left: 0px;
}
.annonce{
    min-width: 590px;
    max-width: 648px;
    display: flex;
    background-color: #fff;
}
.imageAnnonce img{
    width: 310px;
    height: 282px;
    object-fit: cover;
    object-position: 50% 50%;
}
.prix{
    position: relative;
    left: 40px;
    margin: 33px 0px 11px 0;
    font-size: 16px;
    font-family: Avenir;
}
.ttrAnnonce{
    width: 225px;
    position: relative;
    left: 40px;
    font-size: 20px;
    font-family: Avenir;
    color: rgba(24,45,69);
    font-weight: 500;
    text-transform: uppercase;
}
.buttonAnnonce{
    position: relative;
    left: 40px;
    margin-top: 105px;
}
.buttonAnnonce:hover button{
    background-color: #fff;
    color: #5FAAD9;
    border-color: #5FAAD9;
    cursor: pointer;
}
.buttonAnnonce button:hover a{
    color: #5FAAD9 !important;    
    text-decoration: none;
}
.buttonAnnonce button{
    width: 142px;
    height: 40px;
    background-color: #5FAAD9;
    border: 1px solid #5faad983;
    font-family: Avenir;
    letter-spacing: 0.5px;
    transition: 450ms ease-in-out;
}

.buttonAnnonce button a{
    color: #fff !important;
    text-decoration: none;
}

.buttonPlusAnnonce button{
    display: block;
    max-width: 380px;
    min-width: 350px;
    width: 40%;
    margin: 85px auto 0 auto;
    height: 50px;
    border-radius: 4px;
    background: rgba(24,45,69);
    border: none;
    cursor: pointer;
    font-family: Avenir;
    font-size: 16px;
    letter-spacing: 0.5px;
    transition: 450ms ease-in-out;
}

.buttonPlusAnnonce button a{
    color: #fff;
    text-decoration: none;
}

.buttonPlusAnnonce button:hover{
    background: #fff;
    color: rgba(24,45,69);
    border: 1px solid rgba(24,45,69);
}

.buttonPlusAnnonce button a:hover{
    color: rgba(24,45,69);
}

@media only screen and (max-width : 1450px) {
  .cadreFilter{
    margin: 116px 0 0 20%;
  }
}


@media only screen and (max-width : 1175px) {
  .titreAgence{
    margin: 223px 0px 3px 13%;
  }
  .cadreFilter{
    margin: 116px 0 0 50px;
  }
}

@media only screen and (max-width : 1015px) {
  .titreAgence{
    margin: 223px 0px 3px 5%;
  }
  .cadreFilter{
    margin: 116px 0 0 20px;
  }
}

@media only screen and (max-width : 920px) {
  .bannerHeader{
    flex-direction: column;
  }
 .titreAgence{
    margin: 223px 0px 3px 20%;
  }
  .cadreFilter{
    margin: 86px 0 0 20%;
  }
  .titreAgence p{
    width: 370px;
  }
}


/* Téléphone version */

@media only screen and (max-width : 450px) {

  .leftTextHeaderbanner, .rightFilterHeaderbanner{
    width: 100%;
  }
  .titreAgence{
    margin: 103px 0px 3px 5%;
  }
  .titreAgence h1 {
    font-size: 65px !important;
  }
  .titreAgence p{
    width: 300px;
  }
  .cadreFilter {
    width: 100%;
    margin: 26px 0 0 0 !important;
    border: none;

  } 
  .ContaintFilterdiv{
    width: 100%;
    transform: translate(0, 0);
  }
  .formFilter{
    left: 20px;
  }

  .ttrFiltre {
    width: calc(100% - 20px);
    left: 20px;
    margin: 59px 0 0 0 !important;
  }

  .formFilter{
    width: calc(100% - 20px);
    left: 20px;
    grid-template-columns: repeat(2, 160px);
    column-gap: 15px;
  }

  .moreInfo ul{
    width: calc(100% - 20px);
    padding: 0 10px;
  }

  .caseFilter{
    width: 100%;
  }

  .btnFilter {
    width: 330px;
  }
    .annonce{
      width: 350px;
      min-width: auto !important;
      flex-direction: column !important;
    }

    .annonce img{
      width: 100% !important;
    }
    .prix, .ttrAnnonce, .buttonAnnonce{
      left: 20px;
    }

    .buttonAnnonce{
      bottom: 15px;
    }
    .red2{
      display: block !important;
      width: 90%;
    }

    .results ul{
      width: 100%;
      display: flex;
      justify-content: center;
    }

  .error{
    left : 10px;
    top: 0;
  }
}



</style>
