<template>
    <div class="container">

        <HeaderComponent></HeaderComponent>

        <BackgroundComponent></BackgroundComponent>

        <section>
            <div class="ttrSection">
                <h2>A propos de <br> FINANCIEL IMMOBILIER</h2>
                <p> FINANCIEL IMMOBILIER est situé entre le quartier des Batignolles et la Plaine MONCEAU. Spécialisé dans la vente et la location de biens immobiliers mais également de locaux commerciaux et de bureaux,dans Paris intra-muros ainsi que sa proche banlieue. </p>
                <p> Fort d’expérience FINANCIEL IMMOBILIER fera de votre projet une réussite... L’équipe FINANCIEL IMMOBILIER jeune et dynamique se tient à votre disposition et vous réserve un accueil chaleureux.  PROFESSIONNALISME est le maître mot de FINANCIEL IMMOBILIER. Sa principale préoccupation est de vous satisfaire. </p>
                <p> Vous avez des questions ? FINANCIEL IMMOBILIER est là pour vous apporter des réponses et des solutions  Nous vous réservons le meilleur accueil.</p>
            </div>
            <div class="bannierImg">
                <img src="../assets/imgToitParis.webp" alt="toit de Paris">
            </div>
            <div class="histoire">
                <div class="ttrHistoi">
                    <h3> A propos de <br> KAREN  ABISROR </h3>
                    <p> Conseillère en Immobilier au sein de l'agence FINANCIEL IMMOBILIER.</p>
                    <p> L'immobilier est pour moi une véritable vocation. Je mets à profit mes années d'expérience, ma connaissance du marché, et toutes mes compétences afin d'aider mes clients à mener à bien leur projet immobilier. </p>
                    <p> Mon dynamisme, ma réactivité et ma disponibilité sont mes atouts principaux. Ils me permettent de gagner mes challenges et  d'apporter toute satisfaction à ma clientèle. C'est en ce sens que je reste à votre disposition, <br> <br> Karen ABISROR</p>
                </div>
            </div>
            <div class="equipe">
                <div class="ttrEquipe">
                    <h3> Notre équipe </h3>
                </div>
                <div class="containerEquipe">
                    <div class="case">
                        <div class="imgCase">
                            <img src="../assets/thamsContact.png" alt="membre de l'équipe">
                        </div>
                        <div class="info">
                            <h5> Thomas Crouzet </h5>
                            <img src="../assets/linkedin.png" alt="linkedin">
                        </div>
                        <span> Assistant immobilier </span>
                    </div>
                    <div class="case">
                        <div class="imgCase">
                            <img src="../assets/Karen.png" class="karen" alt="membre de l'équipe">
                        </div>
                        <div class="info">
                            <h5> Karen Abisror </h5>
                            <img src="../assets/linkedin.png" alt="linkedin">
                        </div>
                        <span> Directrice </span>
                    </div>
                </div>
            </div>
        </section>

        <footerComponent></footerComponent>

    </div>
</template>

<script>
import HeaderComponent from '../components/HeaderComponent.vue';
import footerComponent from '@/components/footerComponent.vue';
import BackgroundComponent from '@/components/BackgroundComponent.vue';

export default {
  components:{
    HeaderComponent,
    footerComponent,
    BackgroundComponent,
  }
}
</script>

<style scoped>

.container {
  position: absolute;
  width: 100%;
  height: fit-content; 
  overflow: hidden;
}

section{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0d7;
    margin-top: 20px;
}

.ttrSection{
    margin: 87px auto 0 auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ttrSection h2{
    text-align: center;
    font-family: avenir;
    font-size: 46px;
    color: #182D45;
    margin-bottom: 42px;
}
.ttrSection p{
    max-width: 830px;
    font-family: avenir;
    font-size: 18px;
    line-height: 1.8em;
    text-align: justify;
    color: #333;
}

.bannierImg img{
    display: block;
    width: 100%;
    height: 383px;
    margin: auto;
    object-fit: cover;
    object-position: 50% 50%;
    margin-top: 38px;
}
.histoire, .equipe{
    width: 100%;
    margin-top: 80px;
    text-align: center;
}

.ttrHistoi{
    width: 90%;
    margin: auto;
}
.ttrHistoi h3{
    font-family: avenir;
    font-size: 38px;
    color: #182D45;
}
.ttrHistoi p{
    max-width: 830px;
    margin: auto;
    font-family: avenir;
    font-size: 18px;
    color: #333;
    line-height: 1.8em;
    text-align: justify;
}

.equipe{
    margin-bottom: 100px;
}
.ttrEquipe h3{
    font-family: avenir;
    font-size: 38px;
    color: #182D45;
    margin-bottom : 80px;
}

.containerEquipe{
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-around;
}

.containerEquipe .imgCase{
    width: 443px;
    height: 431px;
    background-color: #fcfbfb;
}

.containerEquipe .case img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 2px 2px 4px 3px #3333339f;
}
.info{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0 7px 0;
}
.info h5{
    font-family: avenir;
    color: #182D45;
    font-size: 20px;
    text-align: left;
}
.info img{
    width: 26px !important;
    height: 26px !important;
    cursor: pointer;
    box-shadow: 0 0 0 0 !important;
}
.case{
    text-align: left;
    margin: -20px;
}
.case span{
    width: 100%;
    font-family: avenir;
    font-size: 16px;
    letter-spacing: 0.6px;
}

@media only screen and (max-width: 1150px) {
    .containerEquipe .imgCase{
        width: 343px;
        height: 331px;
    }
    
}

@media only screen and (max-width: 920px) {
    section{
        margin-top: 100px;
    }
    .containerEquipe{
        flex-direction: column;
        row-gap: 100px;
        align-items: center;
    }

    .ttrHistoi p:nth-child(2){
        text-align: center !important;
    }
    .case{
        width: 340px;
        display: flex;
        flex-direction: column;
    }
}



</style>