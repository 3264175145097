// Importez Vuex
import { createStore } from 'vuex';

const store = createStore({
  modules: {
    data: {
      state: {
        biens: [], // Définissez l'état initial des données
      },
      mutations: {
        SET_CURRENT_IMAGE(state, imageName) {
          state.currentImage = imageName;
        },
        setBiens(state, data) {
          state.biens = data; // Mettez à jour les données du store avec les données reçues
        },
      },
      actions: {
        setCurrentImage({ commit }, imageName) {
          commit('SET_CURRENT_IMAGE', imageName);
        },
        fetchData({ commit }, data) {
          commit('setBiens', data); // Appelez la mutation pour mettre à jour les données du store
        },
      },
      getters: {
        getBiens(state) {
          return state.biens; // Récupérez les données du store
        },
      },
      namespaced: true, // Assurez-vous que le module est namespaced
    },
  },
});

export default store;
