<template>
    <div class="container">

        <HeaderComponent></HeaderComponent>

        <section>

            <AnnonceVue></AnnonceVue>

        </section>

        <footerComponent></footerComponent>

        <BackgroundComponent></BackgroundComponent>

    </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import footerComponent from '@/components/footerComponent.vue';
import BackgroundComponent from '@/components/BackgroundComponent.vue';
import AnnonceVue from '@/components/AnnonceVue.vue';

export default {
    components:{
        HeaderComponent,
        footerComponent,
        BackgroundComponent,
        AnnonceVue,
    }

}

</script>

<style scoped>
.container{
    width: 100%;
    overflow: hidden;
}

</style>