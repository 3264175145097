<template>

  <div class="container">
  
      <HeaderComponent></HeaderComponent>
    
      <section>
        <div v-for="bien in limitedBiens" :key="bien.id">
          <div class="image-slider">
            <div class="slide">
              <img v-for="imageUrl in bien.imageUrls.slice(1)" :key="imageUrl" :src="getImageUrl(imageUrl)" alt="Property Image">
            </div>
          </div>
    
          <div class="annonce-details">
            <div class="containerDetails">
              <h1> {{ bien.type_bien[0] }} - {{ bien.ville[0] }} </h1>
              <p>{{ formatPrice(bien.prix) }}</p>
              <p>{{ parseDescription(bien.description_internet) }}</p>
            </div>
          </div>
        </div>

        <div class="bandeEquipement" v-for="bien in limitedBiens" :key="bien.id">
          <div class="containerEquipement">
            <div class="ttrEquipement">
              <h2> Equipement </h2>
            </div>
            <div class="flexgrille">
              <div class="case">
                <img src="../assets/negociation-immobiliere.png" alt="Type de Transaction">
                <span> Type de Transaction </span>
                <span> {{ bien.type_transaction[0] }}</span>
              </div>
              <div class="case">
                <img src="../assets/surface.png" alt="Surface Habitable">
                <span> Surface Habitable </span>
                <span> {{ bien.surface_habitable[0] }}</span>
              </div>
              <div class="case">
                <img src="../assets/porte-ouverte.png" alt="nombre de pièce">
                <span> Nombre de pièce </span>
                <span> {{ bien.nb_piece[0] }} </span>
              </div>
              <div class="case">
                <img src="../assets/salon.png" alt="nombre de chambre">
                <span> Nombre de chambre </span>
                <span> {{ bien.nb_chambre[0] }}</span>
              </div>
              <div class="case">
                <img src="../assets/appartements.png" alt="nombre d'étage">
                <span> Nombre d'étage </span>
                <span> {{ bien.etage[0] }}</span>
              </div>
              <div class="case">
                <img src="../assets/emplacement.png" alt="parking">
                <span> Place de parking </span>
                <span> {{ bien.stationnement[0] }}</span>
              </div>
              <div class="case">
                <img src="../assets/ascenseur.png" alt="Ascenseur">
                <span> Ascenseur </span>
                <span> {{ bien.ascenseur[0] }}</span>
              </div>
              <div class="case">
                <img src="../assets/terrasse.png" alt="Terasse">
                <span> Terrasse </span>
                <span> {{ bien.terrasse[0] }}</span>
              </div>
              <div class="case">
                <img src="../assets/surface.png" alt="SurfaceTerrasse">
                <span> Surface Terrasse </span>
                <span> {{ bien.surface_terrasse[0] }}</span>
              </div>
              <div class="case">
                <img src="../assets/piscine.png" alt="Piscine">
                <span> Piscine </span>
                <span> {{ bien.piscine[0] }}</span>
              </div>
              <div class="case">
                <img src="../assets/invalidite.png" alt="">
                <span> Accès Handicapé </span>
                <span> {{ bien.acces_handicape[0] }}</span>
              </div>
              <div class="case">
                <img src="../assets/cheminee.png" alt="">
                <span> Cheminée </span>
                <span> {{ bien.CHEMINEE[0] }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="bandeNorme" v-for="bien in limitedBiens" :key="bien.id">
          <div class="containerEquipement">
            <div class="ttrEquipement">
              <h2> Normes </h2>
            </div>
            <div class="flexgrille">
              <div class="case">
                <img src="../assets/dpe.png" alt="Bilan DPE">
                <span> Bilan DPE </span>
                <span> {{ bien.bilan_DPE[0] }} </span>
              </div>
              <div class="case">
                <img src="../assets/horloge.png" alt="Date Dpe">
                <span> Date Dpe </span>
                <span> {{ bien.DATEDPE[0] }}</span>
              </div>
              <div class="case">
                <img src="../assets/bilan-de-sante.png" alt="Bilan GES">
                <span> Bilan GES </span>
                <span> {{ bien.bilan_GES[0] }}</span>
              </div>
              <div class="case">
                <img src="../assets/moins-demissions.png" alt="Emission GES">
                <span> Emission GES </span>
                <span> {{ bien.emission_GES[0] }}</span>
              </div>
              <div class="case">
                <img src="../assets/efficacite-energetique.png" alt="Consommation Energetique">
                <span> Consommation Energetique </span>
                <span> {{ bien.consommation_energetique[0] }}</span>
              </div>
              <div class="case">
                <img src="../assets/pompe.png" alt="Fausse Sceptique">
                <span> Fausse Sceptique </span>
                <span> {{ bien.fausse_sceptique[0] }}</span>
              </div>
              <div class="case">
                <img src="../assets/chauffage.png" alt="">
                <span> Type Chauffage </span>
                <span v-html="formatTypeChauffage(bien.type_chauffage[0])"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="BandeContact">
          <div class="containerContact">
            <div class="ttrContact">
              <h2> Une question sur le bien ?</h2>
            </div>
            <form action="">
              <div class="Sujet">
                <label for="sujet">Sujet</label>
                <input type="text" name="sujet">
              </div>
              <div class="Contenue">
                <label for="contenue">Contenue</label>
                <textarea name="contenue" cols="70" rows="20"></textarea>
              </div>
              <div class="button">
                <button> Envoyer </button>
              </div>
            </form>
          </div>
        </div>

      </section>

      <footerComponent></footerComponent>
    
      <BackgroundComponent></BackgroundComponent>

  </div>

</template>

<script>
import axios from 'axios';
const parser = require('xml2js').parseStringPromise;

import HeaderComponent from '@/components/HeaderComponent.vue';
import BackgroundComponent from '@/components/BackgroundComponent.vue';
import FooterComponent from '@/components/footerComponent.vue';

export default {
  components: {
    HeaderComponent,
    BackgroundComponent,
    FooterComponent,
  },
  data() {
    return {
      biens: [],
      limitedBiens: [],
      dataLoaded: false,
    };
  },
  async mounted() {
    await this.fetchDataFromServer();
  },
  methods: {
    async fetchDataFromServer() {
      try {
        const response = await axios.get(`${window.location.origin}/img/base.xml`);
        const xmlData = response.data;
        const result = await parser(xmlData);
        const jsonData = result;
        const id = this.$route.params.ID;
        this.biens = jsonData.biens.bien.filter((bien) => bien.idbien && bien.idbien[0] === id);
        this.dataLoaded = true;
        this.setLimitedBiens();
      } catch (error) {
        console.error(error);
      }
    },
    setLimitedBiens() {
      const id = this.$route.params.ID;
      const annonce = this.biens.find((bien) => bien.idbien[0] === id);
      if (annonce) {
        const images = annonce.images[0];
        const imageUrls = Object.values(images).flat();
        annonce.imageUrls = imageUrls;
        this.limitedBiens = [annonce];
      } else {
        this.limitedBiens = [];
      }
    },
    formatPrice(price) {
      const formattedPrice = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      }).format(price);
      return formattedPrice;
    },
    parseDescription(description) {
      const parser = new DOMParser();
      const parsedHtml = parser.parseFromString(description, 'text/html');
      const parsedText = parsedHtml.documentElement.textContent;
      return parsedText;
    },
    getImageUrl(imageUrl) {
      if (imageUrl && imageUrl.length > 1) {
        return `${window.location.origin}/img/${imageUrl}`;
      }
      return '';
    },
    formatTypeChauffage(typeChauffage) {
      const decodedTypeChauffage = new DOMParser().parseFromString(typeChauffage, 'text/html').body.textContent;
      return decodedTypeChauffage;
    },
  }
};
</script>


<style scoped>

section{
  margin-top: 100px;
  width: 100%;
  height: fit-content;
}

.container{
  width: 100%;
  height: fit-content;
  background-color: #5f87b274;
  overflow: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.image-slider {
  position: relative;
  width: 92%;
  overflow: hidden;
  margin: 0 0 70px auto;
}

.slider-container {
  display: flex;
  width: 100%;
}

.slide {
  flex: 0 0 100%;
  display: flex;
  column-gap: 30px;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.slide img {
  flex: 0 0 25%;
  border-radius: 5px;
  object-fit: cover;
  object-position: center;
  width: 450px;
  height: 450px;
}

.slider-navigation {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.containerDetails{
  width: 90%;
  margin: auto;
}

.annonce-details{
  background-color: #ffffffe0;
  padding: 35px 10px;
  border-radius: 5px;
  width: 85%;
  margin: auto;
}

.annonce-details h1{
  font-size: 24px;
}

.annonce-details p{
  font-size: 18px;
}

.annonce-details h1, .annonce-details p{
  font-family: Avenir;
  color: #2f7099;
  letter-spacing: 0.9px;
}
.annonce-details p:nth-child(2){
  font-size: 20px;
  margin-top: -10px;
  font-weight: bold;
}
.annonce-details p:nth-child(3){
  font-family: Avenir;
  color: #333 !important;
  letter-spacing: 0.9px;
}

.bandeEquipement, .bandeNorme, .BandeContact{
  width: 85%;
  margin: auto;
  border-radius: 5px;
  padding: 40px 10px 50px 10px;
  background-color: #ffffffe0;
  margin-top: 80px;
}

.BandeContact{
  margin-bottom: 90px;
}
.ttrEquipement, .ttrContact{
  width: 85%;
  margin: auto;
  font-family: Avenir;
  letter-spacing: 0.9px;
  margin-bottom: 60px;
  color: #182D45;
}

.flexgrille{
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  column-gap: 55px;
  row-gap: 40px;
}

.case{
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}
.case{
  width: 100px;
  text-align: center;
  font-family: avenir;
}
.case img{
  width: 50px;
  height: 50px;
}

.case span:nth-child(3){
  font-weight: bold;
  font-family: avenir;
}

.Sujet, .Contenue{
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.Sujet label, .Contenue label{
  font-family: avenir;
  font-size: 16px;
}

.Sujet, .Contenue{
  width: 85%;
}

.Sujet input{
  width: 80%;
  height: 40px;
  margin-top: 8px;
  border-radius: 6px;
  border: none;
}

.Contenue{
  margin-top: 10px;
}
.Contenue textarea{
  resize: none;
  width: 80%;
  height: 220px;
  margin-top: 8px;
  border-radius: 6px;
  border: none;
}

.button{
  width: 85%;
  margin: auto;
  display: flex;
}

.button button{
  margin-top: 30px;
  width: 160px;
  height: 35px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #182D45;
  color: #182D45;
  transition: 450ms ease-in-out;
  cursor: pointer;
}
.button button:hover{
  background-color: #182D45;
  border: 1px solid #fff;
  color: #fff;
}

@media only screen and (max-width : 450px) {
  .ttrContact {
    width: 100%;
    text-align: center;
  }
  .annonce-details{
    width: 90%;
  }
  .annonce-details p{
    font-size: 16px;
  }
  .annonce-details h1{
    font-size: 22px;
  }
  .annonce-details p:nth-child(2) {
    font-size: 18px;
    margin-top: -10px;
    font-weight: bold;
  }
  .bandeEquipement, .bandeNorme, .BandeContact {
    width: 90% !important;
  }
  .flexgrille{
    width: 80%;
    display: grid;
    grid-template-columns: 1fr !important;
  }
  .ttrEquipement{
    text-align: center;
  }
  .ttrEquipement, .ttrContact{
    width: 100%;
  }
  .bandeEquipement, .bandeNorme, .BandeContact{
    width: 90% !important;
    display: flex;
    justify-content: center;
    padding: 30px 10px;
    margin-top: 60px;
  }
  .containerEquipement{
    width: 100%;
  }
  .case {
    width: auto;
  }
  .Sujet input, .Contenue textarea {
    width: 100%;
  }

}

</style>
