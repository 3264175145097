<template>
    <header>
        <div class="logo">
            <img src="../assets/logo-removebg-preview.png" alt="">
        </div>
        <div class="containerNavRight">
            <nav>
                <ul>
                    <div class="accueilNav">
                        <li>
                            <RouterLink to="/"> Accueil </RouterLink>
                        </li>
                    </div>
                    <div class="annonceNav">
                        <li>
                            <router-link to="/annonces"> Annonces </router-link>
                        </li>
                    </div>
                    <div class="estimationNav">
                        <li>
                            <router-link to="/estimation">Estimation gratuite</router-link>
                        </li>
                    </div>
                    <div class="aProposNav">
                        <li>
                            <RouterLink to="/aPropos"> à propos </RouterLink>
                        </li>
                    </div>
                    <div class="ContactNav">
                        <li>
                            <RouterLink to="/contact"> Contact </RouterLink>
                        </li>
                    </div>
                </ul>
            </nav>
            <!-- <div class="contactPhone">
                <p class="phoneNumber"> 01.58.60.30.80 </p>

                <p class="phoneNumber"> 07.80.98.82.20 </p>
            </div> -->
        </div>
    </header>
</template>

<style scoped>
/* .phoneNumber {
    cursor: default;
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
} */

header{
    width: 85% !important;
    margin: auto;
    display: flex;
    height: 166px;
    justify-content: space-between;
    padding-top: 20px;
}

.logo{
    background: rgba(13, 25, 38, 0.689);
    border-radius: 6px;
    margin-top: 25px;
}

header .logo img{
    width: 280px;
    height: auto;
    object-fit: cover;
}

nav ul{
    display: flex;
    list-style: none;
    padding-top: 10px !important;
}

nav ul div{
    padding: 0 5px;
}

nav ul div li a{
    padding: 0 10px;
    font-size: 20px;
    font-family: Enriqueta, serif;
    color: #fff;
    text-decoration: none;
    transition: 400ms ease-in-out;
    text-shadow: 1px 2px rgb(61, 61, 61); 
    white-space: nowrap;
}

/* .contactPhone{
    flex-direction: column;
    padding-right: 10px;
    display: flex;
    justify-content: right;
    text-decoration: none !important;
}

.contactPhone p{
    height: fit-content;
    margin: 0;
    display: flex;
    justify-content: right;
    text-decoration: none !important;
    text-shadow: 2px 3px rgb(61, 61, 61);
}

.contactPhone p:nth-child(1){
    font-size: 22px;
    font-family: Avenir;
    margin-top: 10px;
    color: #fff !important;
}
.contactPhone p:nth-child(2){
    font-size: 22px;
    font-family: Avenir;
    margin-top: 13px;
    color: #fff !important;
}
.contactPhone p:nth-child(5){
    font-size: 21px;
    font-family: Avenir;
    margin-top: 9px;
    color: #A5CDE6 !important;
}

.traitPhoneNum{
    width: 32px;
    height: 5px;
    margin: 10px 0 0 auto;
    background-color: #fff;
} */

.containerNavRight{
    display: flex;
    align-items: center;
}

@media only screen and (max-width : 1175px) {

    header{
        width: 90%;
        margin: auto;
    }
    
}

@media only screen and (max-width : 1100px) {
    nav ul div li a{
        font-size: 16px;
    }
}


@media only screen and (max-width : 920px) {
    nav ul{
        width: fit-content;
    }

    header{
        flex-direction: column;
        align-items: end;
        padding-top: 40px;
    }

    .logo{
        display: flex;
    }
    .logo img{
        width: 310px !important;
        height: auto !important;
    }

}


/* Téléphone version */

@media only screen and (max-width : 500px) {

    header{
        width: calc(100%);
    }

    .logo img{
        width: 250px !important;
        height: auto !important;
    }

    .containerNavRight{
        width: calc(100% - 10px);
        min-width: none !important;
        overflow-x: none !important;
    }

    .containerNavRight nav ul{
        width: 200% !important;
        justify-content: space-around;
        display: flex;
        row-gap: 20px;
        padding: 0 0 0 0 !important;
    }


    nav{
        width: 130%;
        margin-left: 20px;
        overflow-x: scroll;
        scroll-behavior: smooth;
    }
    .accueilNav, .annonceNav, .estimationNav, .aProposNav, .ContactNav{
        display: flex;
        justify-content: right;
    }
    .accueilNav a, .annonceNav a, .estimationNav a, .aProposNav a, .ContactNav a{
        font-size: 18px;
        padding: 0;
    }

}


</style>
