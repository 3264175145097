<template>
    <section>
        <div class="ttrSection">
            <h1> Nos Annonces </h1>
        </div>
        <div class="grilleAnnonce">
            <div class="annonce left" v-for="bien in limitedBiens" :key="bien.id">
                <div class="imageAnnonce">
                    <img :src="getImageUrl(bien.images[0])" alt="images annonce">
                </div>
                <div class="contentAnnonce">
                    <div class="prix">
                    <span>{{ formatPrice(bien.prix) }}</span>
                    </div>
                    <div class="ttrAnnonce">
                        <span> {{ bien.type_bien[0] }} - {{ bien.ville[0] }} </span>
                    </div>
                    <div class="buttonAnnonce">
                        <button> 
                            <router-link :to="`/annoncesD/${bien.$.id}`"> 
                                Voir plus 
                            </router-link> 
                        </button>
                    </div>
                </div>
            </div>
        </div>       
    </section>
</template>
  
<script>
import axios from 'axios';
const parser = require('xml2js').parseStringPromise;

export default {
  data() {
    return {
      biens: [],
      limitedBiens: [], // New data property for limited annonces
      dataLoaded: false, // Flag to track data availability
    };
  },
  async mounted() {
    await this.fetchDataFromServer();
  },
  methods: {
    async fetchDataFromServer() {
      try {
        const response = await axios.get('./img/base.xml');
        const xmlData = response.data;
        const result = await parser(xmlData);
        const jsonData = result;
         this.biens = jsonData.biens.bien;
        this.dataLoaded = true; // Set dataLoaded flag to true
        this.setLimitedBiens(); // Set the limitedBiens after data is loaded
      } catch (error) {
        console.error(error);
      }
    },
    setLimitedBiens() {
        this.limitedBiens = this.biens;
    },
    formatPrice(price) {
      const formattedPrice = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      }).format(price);
      return formattedPrice;
    },
    getImageUrl(imageName) {
      const imageKeys = Object.keys(imageName);
      const imageArray = [];
  
      for (const key of imageKeys) {
        if (key !== 'image_princ_min') {
          imageArray.push(imageName[key]);
        }
      }
  
      const concatenatedArray = [].concat(...imageArray);
  
      if (concatenatedArray.length > 0) {
        const imageUrl = concatenatedArray[0];
        return `./img/${imageUrl}`;
      }
  
      return '';
    },
  },
};
</script>
  

<style scoped>

section{
    width: 100%;
    padding: 40px 0;
    background-color: #ffffffe0;
    margin-top: 120px;
    overflow-x: hidden;
    margin-bottom: 35px;
}
.ttrSection{
    width: 100%;
    text-align: center;
    font-family: Avenir;
    font-size: 36px;
    color: #182d45;
    margin-bottom: 80px;
}
.grilleAnnonce{
    width: 95%;
    max-width: 1600px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    row-gap: 80px;
    column-gap: 40px;
}

.annonce{
    min-width: 590px;
    max-width: 648px;
    height: 100%;
    display: flex;
    background-color: rgba(240,240,240);
    box-shadow: 3px 3px 7px 2px #d5d5d5;
}
.imageAnnonce img{
    width: 310px;
    height: 282px;
    object-fit: cover;
    object-position: 50% 50%;
}
.prix{
    position: relative;
    left: 40px;
    margin: 33px 0px 11px 0;
    font-size: 16px;
    font-family: Avenir;
}
.ttrAnnonce{
    width: 225px;
    position: relative;
    left: 40px;
    font-size: 20px;
    font-family: Avenir;
    color: rgba(24,45,69);
    font-weight: 500;
    text-transform: uppercase;
}
.buttonAnnonce{
    position: relative;
    left: 40px;
    margin-top: 105px;
}
.buttonAnnonce:hover button{
    background-color: #fff;
    color: #5FAAD9;
    border-color: #5FAAD9;
    cursor: pointer;
}
.buttonAnnonce button{
    width: 142px;
    height: 40px;
    background-color: #5FAAD9;
    border: 1px solid #5faad983;
    font-family: Avenir;
    letter-spacing: 0.5px;
    transition: 450ms ease-in-out;
}

.buttonAnnonce button a{
    color: #fff;    
    text-decoration: none;
}
.buttonAnnonce button:hover a{
    color: #5FAAD9;    
    text-decoration: none;
}

.buttonPlusAnnonce button{
    display: block;
    max-width: 380px;
    min-width: 350px;
    width: 40%;
    margin: 85px auto 0 auto;
    height: 50px;
    border-radius: 4px;
    background: rgba(24,45,69);
    color: #fff;
    border: none;
    cursor: pointer;
    font-family: Avenir;
    font-size: 16px;
    letter-spacing: 0.5px;
    transition: 450ms ease-in-out;
}
.buttonPlusAnnonce button:hover{
    background: #fff;
    color: rgba(24,45,69);
    border: 1px solid rgba(24,45,69);
}


.left{
    margin: 0 auto 0 auto;
}

@media only screen and (max-width : 1324px) {
    .grilleAnnonce{
        margin-top: 90px;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
        row-gap: 80px;
    }

    .annonce{
        margin: auto;
    }
    
}

@media only screen and (max-width : 700px) {
    .annonce{
        min-width: 490px;
        max-width: 548px;
    }

    .imageAnnonce img{
        width: 280px !important;
        height: 242px !important;
    }
    .prix{
        font-size: 14px;
    }
    .ttrAnnonce{
        position: relative;
        left: 40px;
        font-size: 17px;
    }
    .buttonAnnonce{
        margin-top: 75px;
    }
    .buttonAnnonce button{
        width: 130px;
        height: 32px;
    }
    .buttonPlusAnnonce button{
        width: 80%;
    }

}

@media only screen and (max-width : 920px) {
  .bannerHeader{
    flex-direction: column;
  }
 .titreAgence{
    margin: 223px 0px 3px 20%;
  }
  .cadreFilter{
    margin: 86px 0 0 20%;
  }
  .titreAgence p{
    width: 370px;
  }
}

/* Téléphone version */

@media only screen and (max-width : 450px) {
    .ttrSection h1{
        font-size: 1.1em;
    }

    .buttonPlusAnnonce button{
        min-width: 270px !important;
        max-width: 300px !important;
        font-size: 14px;
        height: 42px;
    }

    .annonce{
        width: 350px;
        min-width: auto !important;
        flex-direction: column;
    }

    .annonce img{
        width: 100% !important;
    }
    .prix, .ttrAnnonce, .buttonAnnonce{
        left: 20px;
    }

    .buttonAnnonce{
        bottom: 15px;
    }

}





</style>