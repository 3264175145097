<template>
    <footer>
        <div class="containerFooter">
            <div class="infoFooter">
                <h2>Votre opinion compte</h2>
                <p>  Laissez-nous un avis pour nous aider à améliorer nos services et partager votre experience avec les autres.</p>
                <div class="moreInfo">
                    <ul>
                        <li>
                            <span> Politique de cookies </span>
                        </li>
                        <li>
                            <span> Mention Légales </span>
                        </li>
                        <li>
                            <span> Politique de confidentialité </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="rightFilterHeaderbanner">
                <div class="divcontainer">
                    <div class="cadreFilter">
                        <div class="ContaintFilterdiv">
                        <div class="ttrFiltre">
                            <span>Votre Avis</span>
                        </div>
                        <form action="" class="formFilter">
                            <div class="caseFilter">
                                <span> Prénom </span>
                                <input type="text" name="prénom">
                            </div>
                            <div class="caseFilter">
                                <span> Nom </span>
                                <input type="text" name="nom">
                            </div>
                            <div class="caseFilter email">
                                <span> E-mail </span>
                                <input type="text">
                            </div>
                            <div class="caseFilter titre">
                                <span> Titre </span>
                                <input type="text">
                            </div>
                            <div class="caseFilter commentaire">
                                <span> Commentaire </span>
                                <textarea></textarea>
                            </div>
                            <div class="btnFilter">
                            <button> Envoyer </button>
                            </div>
                        </form>
                        <div class="error">
                            <p></p>
                        </div>
                        <div class="logo">
                            <div class="image">
                                <img src="../assets/instagram.png" alt="lien_instagram">
                                <img src="../assets/facebook.png" alt="lien_facebook">
                            </div>
                            <div class="copyright">
                                <span> <a href="https://www.linkedin.com/in/j%C3%A9r%C3%A9my-dalmau/">@2023 par Dalmau Jérémy </a> </span>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<style scoped>

footer{
    width: 100%;
    background-color: #182d45c1;
    margin-top: -45px;
    padding: 60px 0;
    position: relative;
}

video{
    z-index: 0;
    position: absolute;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    top: 0;
}

.containerFooter{
    width: 100%;
    max-width: 2000px;
    margin: auto;
    justify-content: space-evenly;
    display: flex;
}

.infoFooter{
    position: relative;
    margin: 105px 0 5px 0;
    width: 400px;
}
.infoFooter h2{
    font-family: Avenir;
    font-size: 45px;
    color: #A5CDE6;
    font-weight: 500;
}
.infoFooter p{
    font-family: Avenir;
    font-size: 15px;
    color: #fff;
    font-weight: 300;
}

.divcontainer{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cadreFilter{
  position: relative;
  margin: 116px 0 0 100px;
  width: 442px;
  min-height: 629px;
  border: 10px solid #fff;
  background-color: #ffffff15;
}

.ContaintFilterdiv{
  position: absolute;
  transform: translate( -35px, 22px);
  width: 455px;
  min-height: 669px;
  background-color: rgba(13,25,38);
}

.ttrFiltre{
  display: flex;
  flex-direction: column;
}

.ttrFiltre{
  position: relative;
  left: 40px;
  margin: 59px 0px 11px 0;
}

.ttrFiltre span:nth-child(1){
  color: rgba(165,205,230);
  letter-spacing: 0.05;
  line-height: 1em;
  font-family: Avenir;
  font-weight: 500;
  font-size: 38px;
  margin-bottom: 11px;
}

.ttrFiltre span:nth-child(2){
  color: #fff;
  font-size: 13px;
  font-family: Avenir;
  font-weight: 300;
  line-height: 1em;
}

.formFilter{
  position: relative;
  left: 40px;
  max-width: 392px;
  height: 484px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 67px);
  row-gap: 20px;
  margin-top: 30px;
  font-family: Avenir;
  font-size: 14px;
}
.caseFilter{
  display: flex;
  flex-direction: column;
  color: rgba(165,205,230);
  width: 177px;
}

.caseFilter span{
  margin-bottom: 14px;
}

.cadreFilter select{
  width: calc(100%);
  padding-left: 8px !important;
  height: 37px !important;
  border: 0;
  color: #182D45;
  font-family: Avenir;
  font-size: 13px;
}

.cadreFilter input{
  width: calc(100% - 10px);
  padding-left: 8px !important;
  height: 37px !important;
  border: 0;
}

.cadreFilter input::placeholder{
  color: #182D45;
  font-family: Avenir;
  font-size: 13px;
}

.email{
    width: 367px;
    grid-column-start: 1;
    grid-column-end: 3;
}
.titre{
    width: 367px;
    grid-column-start: 1;
    grid-column-end: 3;
}

.caseFilter{
    position: relative;
}

.commentaire textarea{
    width: 367px;
    height: 100px !important;
    position: absolute;
    top: 25px;
    grid-column-start: 1;
    grid-column-end: 3;
}

.btnFilter{
  width: 367px;
  grid-column-start: 1;
  grid-column-end: 3;
  margin: 65px 0 7px 0 !important;
}

.btnFilter button{
  width: 100%;
  height: 47px;
  background-color: #A5CDE6;
  font-size: 17px;
  font-family: Avenir;
  font-weight: 500;
  letter-spacing: 0.5px;
  cursor: pointer;
  border: none;
}

.error{
  text-align: center;
  position: relative;
  bottom: 50px;
  margin-bottom: 16px;
  width: 100%;
  color: red;
  font-style: italic;
}

.logo{
  width: fit-content;
  position: relative;
  left: 40px;
  bottom: 15px;
  display: flex;
  color: #fff;
  align-items: center;
}
.copyright{
    font-size: 11px;
    font-family: avenir;
}

.copyright a{
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}
.logo img{
  width: 22px;
  height: 22px;
  object-fit: cover;
  margin-right: 10px;
  cursor: pointer;
}

.moreInfo{
    position: absolute;
    bottom: 0;
    left: 0;
}

.moreInfo ul{
    z-index: 2;
    width: 450px;
    display: flex;
    list-style: none;
    justify-content: space-between;
    color: #fff;
    font-family: avenir;
    font-size: 13px;
    padding-left: 0;
}
.moreInfo ul li span{
    cursor: pointer;
}

@media only screen and (max-width: 1205px) {
    .infoFooter {
        margin: 245px 0 7px 61px;
    }
    .moreInfo ul {
        padding: 0;
        margin-left: 61px;
        top: 65px;
    }
    .containerFooter {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

}
@media only screen and (max-width: 840px) {
    .containerFooter {
        flex-direction: column;
    }

    .infoFooter {
        margin: 0 0 70px 100px;
        width: 65%;
    }

    .cadreFilter {
        margin: 116px 0 0 100px;
        width: 442px;
        margin: auto;
    }

    .moreInfo{
        position: relative;
    }

    .moreInfo ul {
        width: 90%;
        margin: 20px auto;
    }
}

/* Téléphone version */

@media only screen and (max-width : 450px) {
    .cadreFilter {
        width: 100%;
        margin: 66px 0 0 0;
        border: none;
    } 

    .ContaintFilterdiv{
        width: 100%;
        transform: translate(0, 0);
    }
    .formFilter{
        left: 20px;
    }

    .ttrFiltre {
        width: calc(100% - 20px);
        left: 20px;
        margin: 59px 0 0 0 !important;
    }

    .formFilter{
        width: calc(100% - 20px);
        grid-template-columns: repeat(2, 160px);
        left: 20;
        column-gap: 15px;
    }

    .moreInfo ul{
        width: calc(100% - 20px);
        padding: 0 10px;
    }

    .moreInfo li{
        text-align: center;
    }
    .infoFooter{
        margin: 0 0 25px 30px;
        width: 90%;
    }

    .caseFilter{
        width: 100%;
    }

    .btnFilter {
        width: 330px;
    }

    .commentaire textarea {
        width: 330px !important;
    }

}

</style>