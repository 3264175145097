import { createRouter, createWebHistory } from 'vue-router';

// Import your components here
import HomeComponent from './components/HomeComponent.vue';
import ContactComponent from './Vue/ContactComponent.vue'
import aProposComponent from './Vue/aProposComponent.vue'
import EstimationGratuite from './Vue/EstimationGratuite.vue'
import AnnoncePartie from'./Vue/AnnoncePartie.vue'
import AnnonceDetails from './components/AnnonceDetails.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeComponent,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactComponent,
  },
  {
    path: '/aPropos',
    name: 'aPropos',
    component: aProposComponent,
  },
  {
    path: '/estimation',
    name: 'Estimation',
    component: EstimationGratuite,
  },
  {
    path: '/annonces',
    name: 'Annonces',
    component: AnnoncePartie,
  },
  {
    path: '/annoncesD/:ID',
    name: 'AnnoncesDetails',
    component: AnnonceDetails,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

export default router;
