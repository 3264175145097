<template>
    <div class="container">

      <HeaderComponent></HeaderComponent>

      <BackgroundComponent></BackgroundComponent>

      <section>
        <div class="containerSection">
          <div class="infoSection">
            <div class="ttrSect">
              <h2>Contactez-nous</h2>
              <p>
                Remplissez le formulaire ci-dessous ou utilisez les coordonnées fournies pour entrer en contact avec nous. Nous attendons avec impatience de discuter avec vous !"
              </p>
            </div>
            <div class="contact">
              <div class="tel">
                <h4> Téléphone </h4>
                <span> 06.62.32.79.63 </span>
              </div>
              <div class="email">
                <h4> E-mail </h4>
                <span> karen@financiel.org </span>
              </div>
            </div>
            <div class="adress">
              <h4> Addresse </h4>
              <span> 141 Rue Cardinet 75017 Paris France </span>
            </div>
            <div class="SocialNetw">
              <h4> Résaux sociaux </h4>
              <img src="../assets/instagram-NOIR.png" alt="Instagram">
              <img src="../assets/facebook-Noir.png" alt="facebook">
            </div>
          </div>

          <div class="containerForm">
            <form action="">
              <div class="case">
                <label for="prenom"> Prénom </label>
                <input type="text" name="prenom">
              </div>
              <div class="case">
                <label for="Nom"> Nom </label>
                <input type="text" name="Nom">
              </div>
              <div class="case mail">
                <label for="email"> Email </label>
                <input type="text" name="email">
              </div>
              <div class="case msg">
                <label for="message"> Message </label>
                <textarea name="message" id="" cols="30" rows="10"></textarea>
              </div>
              <div class="case">
                <button>Envoyer</button>
              </div>
            </form>          
          </div>
        </div>


        <div class="containerM">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.4935508624476!2d2.3107419764660446!3d48.886930471335994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66fbaaa9c5feb%3A0x534194c37f3a2e3!2s141%20Rue%20Cardinet%2C%2075017%20Paris!5e0!3m2!1sen!2sfr!4v1686122244190!5m2!1sen!2sfr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </section>

      <footerComponent></footerComponent>

    </div>

</template>

<script>
import HeaderComponent from '../components/HeaderComponent.vue';
import footerComponent from '@/components/footerComponent.vue';
import BackgroundComponent from '@/components/BackgroundComponent.vue';

export default {
  components:{
    HeaderComponent,
    footerComponent,
    BackgroundComponent,
  }
}
</script>

<style scoped>

textarea{
  resize: none;
}

.container {
  position: absolute;
  width: 100%;
  height: fit-content; 
  overflow: hidden;
}

.containerSection{
  width: 980px;
  height: 505px;
  margin: auto;
  background-color: #fff;
  display: flex;
  margin-top: 60px;
  padding-bottom: 20px;
}

.infoSection{
  width: 50%;
}

.ttrSect{
  width: 415px;
  position: relative;
  left: 40px;
  margin-bottom: 17px;
}
.ttrSect h2{
  font-size: 53px;
  font-family: avenir;
  color: #182D45;
  margin: 47px 0 18px 0;
}
.ttrSect p{
  font-size: 16px;
  font-family: avenir;
  color: #333;
  font-weight: 300;
  line-height: 1.8em;
}

.contact{
  position: relative;
  left: 50px;
  width: 70%;
  display: flex;
  justify-content: space-between;
}

h4{
  color: #182D45;
  font-family: avenir;
  font-size: 16px;
  margin-bottom: 10px;
}
.contact span{
  color: #333;
  font-family: avenir;
  font-size: 14px;
}

.SocialNetw{
  position: relative;
  left: 50px;
  margin-top: 30px;
}
.SocialNetw img{
  width: 21px;
  height: 21px;
  margin: 0 13px 0 8px;
  cursor: pointer;
}

.containerForm{
  width: 100%;
  display: flex;
  justify-content: center;
}

form{
  width: 421px;
  height: 381px;
  display: grid;
  grid-template-columns: repeat(2, 180px);
  grid-template-rows: repeat(4, 1fr);
  column-gap: 20px;
  margin: 50px auto 0 auto;
  position: relative;
  left: 40px;
}

.case{
  display: flex;
  flex-direction: column;
}

.mail, .msg{
  grid-column-start: 1;
  grid-column-end: 3;
}

.case label{
  font-family: avenir;
  font-size: 14px;
  color: #333;
  margin-bottom: 9px;
}
.case input{
  width: 100%;
  height: 31px;
  padding: 0;
}
.msg textarea{
  height: 86px;
  margin-bottom: 20px;
}

.case button{
  width: 380px;
  height: 36px;
  grid-column-start: 1;
  grid-column-end: 3;
  background-color: #5FAAD9;
  color: #fff;
  font-family: avenir;
  font-size: 14px;
  border-color: #5FAAD9;
  transition: 450ms ease-in-out;
}

.case button:hover{
  background-color: #fff;
  color: #182D45;
  border-color: #182D45;
}

.containerSection2{
  display: flex;
  flex-direction: column;
  width: 980px;
  height: 535px;
  margin: auto;
  background-color: #fff;
  margin-top: 90px;
}
.ttrSect2{
  width: 681px;
  position: relative;
  left: 49px;
}
.ttrSect2 h2{
  font-family: avenir;
  font-size: 38px;
  color: #182D45;
  margin: 40px 0px 20px 0;
}
.ttrSect2 p{
  font-family: avenir;
  font-size: 14px;
  line-height: 1.8em;
  color: #333;
  margin-bottom: 20px;
}

.adress{
  width: 450px;
  position: relative;
  left: 49px;
  margin-bottom: 33px;
  padding-top: 10px;
}

.adress span{
  font-size: 16px;
  font-family: avenir;
  font-weight: 300;
}

.horraire{
  width: 450px;
  position: relative;
  left: 49px;
}
.grille{
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-template-rows: repeat(3, 1fr);
  row-gap: 12px;
}
.case2 span{ 
  font-family: avenir;
  font-size: 16px;
  line-height: 1.8em;
}

.adress h4, .horraire h4{
  font-size: 16px;
  letter-spacing: 0.6px;
}

.containerM{
  width: 980px;
  height: 400px;
  margin: 90px auto 90px auto;
}

.containerM iframe{
  width: 100%;
  height: 100%;
}

@media only screen and (max-width : 1000px){
  .containerSection{
    flex-direction: column;
    width: 700px;
    height: fit-content;
  }

  .containerSection2{
    flex-direction: column;
    width: 700px;
    height: fit-content;
    padding-bottom: 30px;
  }
  .containerForm{
    justify-content: left !important;
  }

  .containerForm form{
    margin: 50px 0 0 0;
  }

  .ttrSect, .ttrSect2{
    width: 530px;
  }

  .ttrSect, .contact, .SocialNetw, .containerForm form{
    left: 80px;
  }

  .containerM{
    width: 700px;
  }

}

@media only screen and (max-width : 920px){
  .containerSection{
    margin-top: 170px;
  }
}

@media only screen and (max-width : 705px){
  .containerSection, .containerSection2{
    flex-direction: column;
    width: 100%;
    height: fit-content;
  }
  .containerM{
    width: 100%;
    margin: 90px auto 90px auto;
  }
  .ttrSect{
    width: 400px;
  }

  .ttrSect2{
    width: 450px;
  }
  
  .contact{
    flex-direction: column;
  }

  .adress{
    width: 450px;
  }

  .horraire{
    width: 450px;
  }
}

/* Téléphone version */

@media only screen and (max-width : 450px) {

  .ttrSect, .contact, .SocialNetw, form, .ttrSect2, .adress, .horraire{
    left: 15px !important;
  }
  .ttrSect p, .ttrSect2, .adress, .horraire, .grille {
    width: 90%;   
  }

  .ttrSect h2{
    font-size: 40px;
  }
  .ttrSect p{
    width: 80%;
    font-size: 14px;
  }

  .case button{
    width: 340px;
  }

  form{
    grid-template-columns: repeat(2, 160px);
  }
}

</style>